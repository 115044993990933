<template>
    <div>
        
        <van-form v-if="ischu == 1">
              <van-row gutter="20">
                   <van-col span="18">
                        <van-field label="过磅重量：" placeholder="请填写过磅重量" v-model="batch_average_weight"> </van-field>
                   </van-col>
                   <van-col span="6" class="list-select1" >
                        <select v-model="batch_average_weight_type">
                            <option :value ="1">Kg</option>
                            <option :value ="2">吨</option>
                        </select>
                   </van-col>
              </van-row>
              <van-row gutter="20">
                   <van-col span="18">
                        <van-field label="扣杂重量：" placeholder="请填写扣杂重量" v-model="suttle_batch_average_weight" ></van-field>
                   </van-col>
                   <van-col span="6" class="list-select1">
                        <select  v-model="suttle_batch_average_weight_type">
                            <option :value ="1">Kg</option>
                            <option :value ="2">吨</option>
                        </select>
                   </van-col>
              </van-row>
             <van-field label="自定义编码：" placeholder="请填写自定义编码" v-model="defined_num" />
             <van-field
                readonly
                clickable
                name="picker"
                v-model="purchaseName"
                label="采购类型："
                placeholder="点击选择采购类型"
                @click="showPicker = true"
                />
                <van-popup v-model="showPicker" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="columns"
                    value-key="name"
                    @confirm="onConfirm(1,$event)"
                    @cancel="showPicker = false"
                />
                </van-popup>
                  
            <van-field
                v-if="this.purchaseType !== 1"
                readonly
                clickable
                name="picker"
                v-model="ChanneNmae"
                label="选择采购渠道："
                placeholder="选择采购渠道"
                @click="show = true"
                >
                    <template #button>
                    <van-button size="small" @click.stop="addChangeHan" type="info">新增渠道</van-button>
                </template>
                </van-field>
                <van-popup v-model="show" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="ChanneList"
                    value-key="shopName"
                    @confirm="onConfirm(2,$event)"
                    @cancel="show = false"
                />
                </van-popup>
                 <!-- //业务员 -->
                <van-field
                    readonly
                    clickable
                    name="picker"
                    v-model="principalUserName"
                    label="选择业务员："
                    placeholder="选择业务员"
                    @click="userShow = true"
                    ></van-field>
                <van-popup v-model="userShow" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="userListInfo"
                    value-key="realName"
                    @confirm="onConfirm(6,$event)"
                    @cancel="userShow = false"
                />
                </van-popup>



                <van-field name="radio" label="是否监销">
                    <template #input>
                        <van-radio-group v-model="is_sale_of" direction="horizontal">
                        <van-radio name="1">是</van-radio>
                        <van-radio name="2">否</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

                 <van-field
                    readonly
                    clickable
                    name="picker"
                    v-model="vehicleType"
                    label="自定义车辆类型："
                    placeholder="点击选择车辆类型"
                    @click="vehicleTypeShow = true"
                    />
                    <van-popup v-model="vehicleTypeShow" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="vehicleTypeColumns"
                        value-key="defined_type"
                        @confirm="onConfirm(3,$event)"
                        @cancel="vehicleTypeShow = false"
                    />
                </van-popup>
                <!-- <p>{{serverId}}</p> -->
                <div v-if="!isWechat">
                    <van-field name="uploader" label="图片上传">
                        <template #input>
                            <van-uploader  v-model="imageFile" :after-read="uploadershang('restsImg')" :before-read="beforeRead" multiple  :before-delete="deleteimg2"></van-uploader>
                            <!-- <div v-for="(item,index) in WXimg" :key="index">
                                <van-image width="80"  height="80"  :src="item" />
                            </div> -->
                        </template>
                        
                    </van-field>
                </div>

                <div class="upimg" v-else>
                    <p>图片上传</p>
                    <p>
                        <span class="imgbox" v-for="(item,index) in WXimg" :key="index">
                            <van-image class="vanImage" width="80"  height="80"  :src="item" @click="bigImg(item)" >
                                <van-icon class="iconX" name="close" @click="delImgHan('arrPhoto',index)" />
                            </van-image>
                        </span>
                        <span class="uploaderImg" @click="WeChooseImage(9)"> 
                            <van-icon class="iconz" name="plus" />
                        </span>
                    </p>
                </div>

                 <van-field
                    readonly
                    clickable
                    name="picker"
                    v-model="allocation_type"
                    label="拆解分配机制："
                    placeholder="请选择分配机制"
                    @click="typePicker = true"
                    />
                    <van-popup v-model="typePicker" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="allocation"
                        value-key="name"
                        @confirm="onConfirm(4,$event)"
                        @cancel="typePicker = false"
                    />
                    </van-popup>

                <van-field v-model="dismantle_allocation_remark" rows="2"  autosize label="拆解要求" type="textarea"  placeholder="请输入拆解要求"  show-word-limit />

                <div class="table" v-if="tableData.length!=0">
                        <table >
                            <tr>
                                <td>项目</td>
                                <td>单价</td>
                                <td>数量</td>
                                <td>增费</td>
                                <td>操作</td>
                            </tr>
                            <tr v-for="(i,index) in tableData" :key="i.id">
                                <td>{{i.name}}</td>
                                <td>{{i.price}}</td>
                                <td>{{i.num}}</td>
                                <td>{{i.num * i.price}}</td>
                                <td @click="deletes(index,1)">删除</td>
                            </tr>
                        </table>
                </div>
                <p class="sun" v-if="tableData.length!=0">
                    <span>质检增费合计：</span><span>{{Q}}</span>
                </p>
                <div  class="btn_zhij1">
                    <van-button  type="primary" size="large" @click="addProject">添加/修改质检增费项目</van-button>
                </div>
                <div class="table"  v-if="DetetData.length!=0">
                    <table >
                        <tr>
                            <td>项目</td>
                            <td>单价</td>
                            <td>数量</td>
                            <td>扣费</td>
                            <td>操作</td>
                        </tr>
                        <tr v-for="(i,index) in DetetData" :key="i.id">
                            <td>{{i.name}}</td>
                            <td>{{i.price}}</td>
                            <td>{{i.num}}</td>
                            <td>{{i.num * i.price}}</td>
                            <td @click="deletes(index,2)">删除</td>
                        </tr>
                    </table>
                </div>
                <p class="sun"  v-if="DetetData.length!=0">
                    <span>质检扣费合计：</span><span>{{QQ}}</span>
                </p>
                <div  class="btn_zhij1">
                    <van-button   type="primary" size="large" @click="addDetention">添加/修改质检扣费项目</van-button>
                </div>
             <!-- <van-field label="质检增费：" v-model="quality_inspection" />
             <van-field label="质检扣费：" v-model="quality_inspection_deduction" /> -->
            
        </van-form>
        <div  class="btn_zhij" >
            <van-button  type="info" size="large" @click="submit">完成</van-button>
        </div>
         <van-popup
            v-model="isAddShow"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
            <AddChannel ref="Channels" @addditch="addditch"  />
    </van-popup>
        <van-popup v-model="ProjectShow" closeable class="popupProject" :close-on-click-overlay="false">
            <IncreaseTheProject ref="Increase" @addOroject="addOroject" />
        </van-popup>
        <van-popup v-model="FeeShow" closeable class="popupProject" :close-on-click-overlay="false">
            <feeDeduction ref="DetentRef" @FeeOroject="FeeOroject" />
        </van-popup>
         <van-popup
                v-model="CarInfoShow"
                closeable
                :close-on-click-overlay="false"
                position="bottom"
                :style="{height: '100%',width:'100%'}">
                <div class="title_car">车辆列表</div>
                <ObtainCarInfo ref="ObtainRef" :repetition="repetition" @colosepopup="colosepopup" :vin="vindata" @comeWei="comeWei" @upDate="upDate" />
              
            </van-popup>
    </div>
</template>

<script>
import {Dialog,Toast} from 'vant'
import AddChannel from '../views/Quality/components/AddChannel.vue'
import IncreaseTheProject from '../views/Quality/components/IncreaseTheProject.vue'
import feeDeduction from '../views/Quality/components/feeDeduction.vue'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { is_post,post } from "@/Http/api.js";
import ObtainCarInfo from '@/components/ObtainCarInfo'
import {PlateNum} from '@/api/carInfo'
    export default {
        props:['vindata','isData','ischu'],
        components: {
            AddChannel,IncreaseTheProject,feeDeduction,ObtainCarInfo
        },
        data() {
            return {
                 wximagelocalids:[],//判断上传张数
                islable:"",//判断上川的什么类型照片进行识别
                tableData: [],//自检费
                DetetData: [],//自检费
                fileList1: [],
                imageFile: [],//其他照片
                imageArr: [],//其他照片
                vehicleTypeColumns: [],//自定义车辆类型
            
                imageUrl:"",//45度
                voiceShow:false,
                FeeShow:false,
                typePicker:false,//拆解机制
                
                ProjectShow:false,
                vehicleTypeShow:false,//自定义车辆类型
                vehicleType:'',//自定义车辆类型
                vehicleTypeId:'',//自定义车辆类型
                isOk:false,//弹出确认
                voice:'',
                is_sale_of:'2',//是否监销
                longClick:0,
                timeOutEvent:0,
                fileList2:[],
                VinUrl:"",
                userListInfo:[],//负责人
                userShow:false,
                principalUserId:'',
                principalUserName:'',
                purchaseName:'普通采购',//采购选择汉子
                showPicker:false,//采购选择弹窗
                show:false,//渠道弹窗
                isAddShow:false,//新增渠道
                ChanneList:[],//渠道列表
                ChanneNmae:'',//渠道列表
                WXimg:[],//其他照片
                 columns: [
                    // '普通采购','渠道采购'
                    {id:1,name:'普通采购'},
                    {id:2,name:'渠道采购'},
                 
                ],
                allocation:[
                    
                     {id:1,name:'预处理后自动拆解'},
                     {id:2,name:'预处理后资料合格手动拆解'},
                     {id:3,name:'预处理后资料合格自动拆解'},
                ],
                dismantle_allocation_type:2,//拆解机制
                allocation_type:'预处理后资料合格手动拆解',//拆解机制
                dismantle_allocation_remark:'',//拆解要求
                defined_num:'',//自定义编号
                purchasingChannelsId :'',//采购渠道
                purchaseType :1,//采购类型
                other_photos :'',//其他照片
                quality_inspection :'',//质检增费
                quality_inspection_deduction :'',//质检扣费
                quality_inspectionId :'',//质检增费
                quality_inspection_deductionId :'',//质检扣费
                batch_average_weight :'',//过磅重量
                suttle_batch_average_weight :'',// 扣杂重量
                quality_show:false,//增肥
                inspection_show:false,//扣费
                quality_List:[],//增肥数组
                inspection_List:[],//扣费数组
                batch_average_weight_type:1,
                suttle_batch_average_weight_type:1,
                toast:null,
                  CarInfoShow:false,
                repetition:[],
                imageFileArr:[],
            }
        },
         watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
         computed: {
           
            Q() {
                let num = 0
                if(this.tableData.length!=0){
                   this.tableData.forEach(i=>{
                       num += Number(i.num * i.price)
                   })
                }
                return num
            },
            QQ() {
                let num = 0
                if(this.DetetData.length!=0){
                   this.DetetData.forEach(i=>{
                       num += Number(i.num * i.price)
                   })
                }
                return num
            },
        },
         methods: {
             colosepopup(){
                 this.CarInfoShow = false
            },
            comeWei(){
                this.CarInfoShow = false
                this.onsubmit()
            },
              //负责人
              userList(){
                this.$http.post('index.php/batch/userList').then(res=>{
                    console.log(res)
                    if(res.data.code == 0){
                        this.userListInfo = res.data.data
                    }
                })
            },
            upDate(e){
               console.log( this.$parent.vindata)
                this.$parent.vindata = e[0]
                this.vindata = e[0]
                this.CarInfoShow = false
                 this.onsubmit()
            },
            async submit (){
                if(this.isData.carNumberPlate && this.vindata){
                     const {data} = await PlateNum({value:this.isData.carNumberPlate}).then(res=>res)
                    
                    let arr =  data.data
                    if(arr.length==0){
                        this.onsubmit()
                    }else if(arr.length==1){
                        if(arr[0].carNumberPlate == this.isData.carNumberPlate && arr[0].vin == this.vindata){
                            this.onsubmit()
                        }else{
                            this.repetition = arr
                            this.CarInfoShow = true
                             this.$nextTick(()=>{
                                this.$refs.ObtainRef.checkbox = []
                            })
                        }
                    }else if(arr.length > 1){
                        this.repetition = arr
                        this.CarInfoShow = true
                        this.$nextTick(()=>{
                            this.$refs.ObtainRef.checkbox = []
                        })
                    }
                 }else{
                      this.$toast.fail('车牌号或者车架号不能为空！')
                 }
                
            },
            //删除
             deletes(i,num){
                if(num == 1 ){
                    this.tableData.splice(i,1)
                }else {
                    this.DetetData.splice(i,1)
                }
            },
            //质检增费
            addOroject(e){
                console.log(e)
                this.ProjectShow = false
                let o=false
                this.tableData.forEach(i=>{
                    if(i.id === e.id){
                        i.num = e.num
                        o = true
                    }
                })
                if(!o){
                    this.tableData.push(e)
                }
            },
            //质检扣费
            FeeOroject(e){
                 this.FeeShow = false
                 let o=false
                this.DetetData.forEach(i=>{
                    if(i.id === e.id){
                        i.num = e.num
                        o = true
                    }
                })
                if(!o){
                    this.DetetData.push(e)
                }
            },
            //质检扣费
            addDetention(){
                this.FeeShow = true
                 this.$nextTick(()=>{
                    this.$refs.DetentRef.getData()
                })
            },
            //质检增费
            addProject(){
                this.ProjectShow = true
                this.$nextTick(()=>{
                    this.$refs.Increase.getData()
                })
            },
            //选择采购类型
            onConfirm(i,v){
              
                if( i==1 ){
                    this.purchaseName = v.name
                    this.purchaseType = v.id ;
                    if(this.purchaseType != 1) {
                        this.getPurchasing()
                    }else{
                        this.principalUserId = ''
                        this.principalUserName = ''
                        this.purchasingChannelsId = ''
                        this.ChanneNmae = ''
                    }
                    this.showPicker = false;
                }else if( i==2 ){
                    this.purchasingChannelsId = v.id
                    this.ChanneNmae = v.shopName
                    this.repetitionHan(v)
                    this.show = false;
                }else if( i==3 ){
                    this.vehicleType = v.defined_type
                    this.vehicleTypeId = v.id
                    this.vehicleTypeShow = false
                }else if( i==4 ){
                    this.allocation_type = v.name
                    this.dismantle_allocation_type = v.id
                    this.typePicker = false
                }else if( i==6){
                    this.principalUserId=v.id
                    this.principalUserName=v.realName
                    this.userShow = false
                }
            },
             //查找相关负责人
             async repetitionHan(val){
                try{
                    let data = encipherMent( JSON.stringify({id:val.id}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/index/Purchasing_Channels/_get',param).then(res=>res)
                    if(res.data.realName){
                        if(this.principalUserId){
                            Dialog.confirm({
                            title:'提示' ,
                            message: `渠道中有关联业务员 ${res.data.realName} 是否更换？`,
                            theme: 'round-button',
                            }).then(() => {
                                this.principalUserId = res.data.salesmanId
                                this.principalUserName = res.data.realName
                            })
                            .catch(() => {});
                        }else{
                            this.principalUserId = res.data.salesmanId
                                this.principalUserName = res.data.realName
                        }
                    }
                }
                catch{}
            
            }, 
            async getMechanismSet(){
                let that=this;
                try{
                    console.log("getMechanismSet",that.dismantle_allocation_type)
                    const res = await post('/index.php/setUp/info',{id : 22}).then(res=>res)
                    console.log("getMechanismSet",res)
                    if(res.data.code ==0){
                        let str = res.data.data.default
                        if(str == '预处理后自动拆解'){
                            that.dismantle_allocation_type = 1
                            that.allocation_type = str
                        }else if(str == '预处理后资料合格手动拆解'){
                            that.dismantle_allocation_type = 2
                            that.allocation_type = str
                        }else if(str == '预处理后资料合格自动拆解'){
                            that.dismantle_allocation_type=3
                            that.allocation_type = str
                        }
                    }
                }
                catch{}
                console.log("getMechanismSet",that.dismantle_allocation_type)
            
            }, 
            
            //质检列表
            getQuality(){
                this.getMechanismSet()
                this.userList()
                this.name = ''
                this.number = ''
                this.$http.post('index.php/quality_price/_list').then(res=>{
                    if(res.data.code == 0) {

                        this.isArr =res.data.data
                        let arr = []
                        res.data.data.forEach(i => {
                            let obj = {
                                test:i.name ,
                                price: i.price,
                                id:i.id
                            }
                            arr.push(obj)
                        });
                        this.quality_List = arr
                    }else if(res.data.code == -1) {
                           this.islogout()
                       }else{
                           this.$toast.fail(res.data.msg)
                       }
                })
                this.$http.post('index.php/quality_price_deduction/_list').then(res=>{
                    if(res.data.code == 0){
                        this.isArr =res.data.data
                        let arr = []
                        res.data.data.forEach(i => {
                            let obj = {
                                test:i.name,
                                price: i.price,
                                id:i.id
                            }
                            arr.push(obj)
                        });
                        this.inspection_List = arr
                    }else if(res.data.code == -1) {
                           this.islogout()
                       }else{
                           this.$toast.fail(res.data.msg)
                       }
                })
                this.$http.post('index.php/defined_num/_list').then(res=>{
                    if(res.data.code == 0){
                        console.log(res)
                        this.vehicleTypeColumns = res.data.data
                    }else if(res.data.code == -1) {
                           this.islogout()
                       }else{
                           this.$toast.fail(res.data.msg)
                       }
                })
            },
              //新增渠道
            addChangeHan(){
                this.isAddShow = true
                this.$nextTick(()=>{
                    this.$refs.Channels.getNmae()
                })
            },
            //获取采购渠道
             getPurchasing(){
                this.$http.post('index.php/index/Purchasing_Channels/purchasingChannelsList2').then(res=>{
                    this.ChanneList = res.data.data
                })
            },
             //新增渠道取消
            addditch(e){
                if(e){
                   this.purchasingChannelsId = e.id
                   this.ChanneNmae = e.shopName
                }
                this.isAddShow = false
            },
            onsubmit (){
                if(this.ischu==2){
                    this.getMechanismSet()
                    console.log("先载入预设拆解方式")
                }
                console.log("执行提交")
                if(this.isData.carNumberPlate && this.vindata){
                    Dialog.confirm({
                    title: '提示',
                    message:
                        `<p>请再次核对车牌号及车架号是否正正确</p><p>车牌号:${this.isData.carNumberPlate?this.isData.carNumberPlate:''}</p><p>车架号:<br>${this.vindata}</p>`,
                    })
                    .then(async() => {
                        // on confirm
                        let arr = {}
                        let arr1 = {}
                            this.tableData.forEach(i=>{
                                let obj = {
                                    [i.id]:{
                                        price:i.price,
                                        num:i.num
                                    }
                                }
                                arr = {...arr,...obj}
                            })
                    
                            this.DetetData.forEach(i=>{
                                let obj = {
                                    [i.id]:{
                                        price:i.price,
                                        num:i.num
                                    }
                                }
                                arr1 = {...arr1,...obj}
                            })
                            
                            // if(this.isWechat){
                            // }
                            this.imageUrl = this.isWechat? this.$parent.imageUrl: this.$parent.du4
                    
                            this.VinUrl = this.$parent.VinUrl
                            this.imageHui = this.$parent.imageHui
                            this.up_files = this.$parent.up_files
                            this.break_beam_photos = this.$parent.break_beam_photos
                            this.imageFileArr = this.$parent.imageFileArr
                        let obj = {
                            carNumberPlate:this.isData.carNumberPlate,//车牌号
                            vin:this.vindata,//VIN
                            destruction_photos_data:this.imageFileArr,//毁形留档
                            path:this.imageUrl,//车牌照片
                            vin_photos:this.VinUrl,//VIN你照片
                            destruction_photos:this.imageHui,
                            destruction_video:this.up_files,
                            break_beam_photos:this.break_beam_photos,

                            purchasingChannelsId:this.purchasingChannelsId,//采购渠道
                            purchaseType:this.purchaseType,//采购类型
                            other_photos:this.WXimg,//其他照片
                            // other_photos:this.imageArr,//其他照片
                            // quality_inspection:this.quality_inspectionId,//质检增费
                            quality_inspection:arr,//质检增费
                            // quality_inspection_deduction:this.quality_inspection_deductionId,//质检扣费
                            quality_inspection_deduction:arr1,//质检扣费
                            defined_num:this.defined_num,//自定义编号
                            weight:this.batch_average_weight,//过磅重量
                            weight_type:this.batch_average_weight_type,//单位
                            deduction_weight:this.suttle_batch_average_weight,//  扣杂重量
                            deduction_weight_type:this.suttle_batch_average_weight_type,//单位
                            is_sale_of:this.is_sale_of,
                            defined_num_id:this.vehicleTypeId,
                            dismantle_allocation_type:this.dismantle_allocation_type,
                            dismantle_allocation_remark:this.dismantle_allocation_remark,
                            principalUserId:this.principalUserId,
                        }
                       
                        try{
                            let data = encipherMent( JSON.stringify(obj))
                            let param = new URLSearchParams();
                            param.append("value",data);
                            const res = await is_post('index.php/vehicles_upload/saveCarNumberPlate',param).then(res=>res)
                            console.log(res)
                            this.$emit('infoOk')
                        }
                        catch (err){
                            if(err.code == 9999){
                                this.$emit('infoOk')
                            }else{
                                this.$toast.fail(err.msg)
                            }
                            
                        }
                    })
                    .catch(() => {
                        // on cancel
                    });
                 }else{
                      this.$toast.fail('车牌号或者车架号不能为空！')
                 }
                
            },
             deleteimg2(file,datail){
              this.imageFile.forEach((item,index)=>{
                   if(index == datail.index){   // 判断删除的下标是否正确
                        this.imageFile.splice(index,1) // 使用遍历找到的index去数组里面删除
                    }
              })
              this.WXimg.forEach((item,index)=>{
                if(index == datail.index){   // 判断删除的下标console.log(this.WXimg)
                    this.WXimg.splice(index,1) // 使用遍历找到的index去数组里面删除
                }
            })
              this.imageArr.forEach((item,index)=>{
                 
                   if(index == datail.index){   // 判断删除的下标是否正确
                        this.imageArr.splice(index,1) // 使用遍历找到的index去数组里面删除
                    }
              })
           },
              // 上传照片
           uploadershang(i){
                return file =>{
                     file.status = "uploading";
            file.message = "上传中...";
                    if(file instanceof Array){
                        file.forEach(item=>{
                           
                            this.upImages(i,item)
                         
                            console.log(i)
                        })
                    }else{
                        this.upImages(i,file)
                    }
                }
           },
           upImages(i,file){
            file.status = "uploading";
            file.message = "上传中...";
            let params = new FormData(); //创建form对象
            params.append("file", file.file);
            this.$http.post("/index.php/index/upload/index", params)
            .then((res) => {
                // toast.clear()//清除加载效果
            if (res.data.code == 0) {
                file.status = "done";
                    if(i=='du'){
                        let url = 'index.php/vehicles_upload/vehicleCarNumberPlate'
                        this.imageUrl = res.data.data
                        this.recognition(url,{path:res.data.data})
                    }else if(i=='vin'){
                        let url = '/index.php/index/bid_management.bid_order/apiBaiDu'
                        this.VinUrl = res.data.data
                        this.recognition(url,{url:res.data.data},'vin')
                    }else if(i=='restsImg'){
                        this.WXimg.push(res.data.data)
                    }
                } else if(res.data.code == -1){
                    deleteCookie('token')
                    this.$toast.fail(res.data.msg)
                    this.$router.push('/')
                }else{
                    file.status = "failed";
                   file.message = "上传失败";
                    // toast.clear()//清除加载效果
                    // if(i=='du'){
                    //     this.imageUrl = ''
                    //     this.fileList1=[]//行驶证正面照片
                    // }else if(i=='vin'){
                    //     this.VinUrl = ''
                    //     this.fileList2=[]//行驶证正面照片
                    
                    // }else if(i=='restsImg'){
                    //     file.status = "failed";
                    //      file.message = "上传失败";
                    // }
                    this.$toast.fail(res.data.msg)
                }
            }).catch(()=>{
                 if(i=='du'){
                    this.imageUrl = ''
                    this.fileList1=[]//行驶证正面照片
                }else if(i=='vin'){
                    this.VinUrl = ''
                    this.fileList2=[]//行驶证正面照片
                }else if(i=='restsImg'){
                    this.imageFile = []
                }
                // toast.clear()//清除加载效果
                file.status = "failed";
                file.message = "上传失败";
                this.$toast.fail('网络开小差了，请稍后重试！')
            })
           }
        },
    }
</script>

<style lang="scss" scoped>

.content-btn{
    margin: 50px 0;
}
.content{
    padding: 10px;
    padding-top: 50px;
    margin-top: 20px;
    text-align: center;
    .content-up{
        margin-top: 20px;
        padding: 10px;
      
    }
}
  .popup{
    width: 80%;
    padding: 20px;
    .btn-ok{
        width: 100%;
        display: flex;
        .van-button{
            flex: 1;
        }
    }
}

.list-select1{
    line-height: 30px;
}
.table{
    padding:10px;
    margin-top: 10px;
    table,table tr th, table tr td { border:1px solid #000; }
    table { 
        width: 100%;
        text-align: center; 
        border-collapse: collapse; 
        
    }
}
.btn_zhij{
    padding: 20px;
    text-align: center;
    .van-button{
        margin-bottom: 5px;
    }
}
.btn_zhij1{
    padding: 0 20px;
    text-align: center;
     .van-button{
        margin-bottom: 5px;
    }
}
.popupProject{
    width: 80%;
}
.upimg{
    padding: 0 12px;
    .uploaderImg{
        display: inline-block;
        width: 100px ;
        height: 100px ;
        border: 1px solid #eee;
        position: relative;
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>