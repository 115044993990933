<template>
    <div>
       <div class="header">
             <span @click="gouot"></span>
             <span>毁形照</span>
             <span @click="islogout">退出</span>
        </div>

        <div class="music-t">
            <music />
        </div>
         <div class="content">
            <div class="content-up" >
                <p v-if="isdefault == '是'">车辆45°角方向：</p>
                <p v-else>车牌号识别：</p>
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList4" :after-read="uploadershang('du')" :before-delete="deleteimg(1)" preview-size="100%" :max-count="1">
                         <img  style="width:100%" src="../../assets/zhanwu.jpg" alt="" >
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!imageUrl" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'45Degrees')">
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="imageUrl" @click="bigImg(imageUrl)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('45Degrees')" />
                        </van-image>
                    </div>
                </div>
            </div>
            <div class="content-up">
                <p>毁形视频</p>
                <div class="videobox" v-for="(item,index) in up_files" :key="index">
                    <van-icon class="iconX" name="close" @click="iconClose(index)" />
                    <video class="videoP"  :src="item" v-if="up_files.length !=0" 
                    playsinline="true"
                    controls = "true"
                    autoplay
                    webkit-playsinline="true" 
                    preload="auto" type="video/mp4" 
                    x-webkit-airplay="allow" 
                    x5-video-player-type="h5" 
                    x5-video-player-fullscreen="true" 
                    x5-video-orientation="portraint" 
                    style="object-fit:fill"></video>
                </div>
                <!-- :before-read="beforeRead" -->
                <van-uploader :after-read="afterRead" :before-read="beforeRead" v-model="fileList3" accept="video/*" :preview-image="false">
                    <van-button><van-icon name="plus" size="30" /></van-button>
                </van-uploader>
                <!-- <van-uploader :after-read="afterRead" 
                accept="avi , flv , mpg , mpeg , mpe , m1v , m2v , mpv2 , mp2v , dat , ts , tp , tpr , pva , pss , mp4 , m4v , m4p , m4b , 3gp , 3gpp , 3g2 , 3gp2 , ogg , mov , qt , amr , rm , ram , rmvb , rpm"
                capture="camera"/> -->

            </div>
            <div class="content-up">
                <p>毁形之后的照片：</p>
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList1" :after-read="uploadershang('hui')" :before-delete="deleteimg(2)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!imageHui" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'hui')">
                
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="imageHui" @click="bigImg(imageHui)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('hui')" />
                        </van-image>
                    </div>
                </div>
            </div>
            <div class="content-up">
                <p>毁形留档照片：</p>
                <div v-if="!isWechat">
                    <van-field name="uploader" >
                        <template #input>
                            <van-uploader  v-model="imageFile" :after-read="uploaderImgArr('restsImg')" :before-read="beforeRead2" multiple  :before-delete="deleteimg2"></van-uploader>
                        </template>
                    </van-field>
                </div>
                <div v-else>
                    <div  style="width:100%"  class="upimg">
                        <p>
                            <span class="imgbox" v-for="(item,index) in imageFileArr" :key="index">
                                <van-image class="vanImage" width="80"  height="80"  :src="item" @click="bigImg(item)" >
                                    <van-icon class="iconX" name="close" @click="delImgHan('imageFile',index)" />
                                </van-image>
                            </span>
                            <span class="uploaderImg" @click="WeChooseImage(9,'imageFileArr')"> 
                                <van-icon class="iconz" name="plus" />
                            </span>
                        </p>
                    </div>
                </div>
            </div>


            <div class="content-up">
               
                <p>Vin码照片：</p>
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList2" :after-read="uploadershang('vin')" :before-delete="deleteimg(3)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!VinUrl" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'Vin')">
                
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="VinUrl" @click="bigImg(VinUrl)" >
                            <van-icon class="iconX" name="close" @click="delImgHan('Vin')" />
                        </van-image>
                    </div>

                </div>
            </div>
            <div class="content-up">
               
                <p>断梁照：</p>
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList5" :after-read="uploadershang('duan')" :before-delete="deleteimg(4)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!break_beam_photos" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'duan')">
                
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="break_beam_photos" @click="bigImg(break_beam_photos)" >
                            <van-icon class="iconX" name="close" @click="delImgHan('duan')" />
                        </van-image>
                    </div>

                </div>
            </div>
        </div>
        <div>
             <van-field   label="车牌号：" v-model="isData.carNumberPlate" />
             <van-field label="Vin：" v-model="vindata" />
             <vehicleInfo  v-show="isdefault == '是'" :ischu="ischu"  ref="infoCarRef" :isData="isData" :vindata="vindata" @infoOk="infoOk" />

            <p v-if="ischu == 2&&isdefault == '是'" class="opopzi" @click="lookinfo">查看更多资料</p>
            <p v-if="ischu == 1&&isdefault == '是'" class="opopzi" @click="ischu = 2">隐藏更多资料</p>
        </div>
        <van-button v-if="isdefault == '否'" class="content-btn" type="info" size="large" @click="onsubmit">完成</van-button>

         <van-popup  :closeable="true"  v-model="voiceShow" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">是否语音播报</p>
              <hr>
              
              <div class="btn-ok">
                  <van-button @click="voiceShow= false">取消</van-button>
                  <van-button type="info" @click="yuying">确定</van-button>
              </div>
        </van-popup>
        <audio :src="voice" id="audio"  ref="au"></audio>
        <van-popup
                v-model="CarInfoShow"
                closeable
                :close-on-click-overlay="false"
                position="bottom"
                :style="{height: '100%',width:'100%'}">
                <div class="title_car">车辆列表</div>
                <ObtainCarInfo ref="ObtainRef" :repetition="repetition" @colosepopup="colosepopup" :vin="vindata" @comeWei="comeWei" @upDate="upDate" />
              
            </van-popup>
    </div>
</template>

<script>
import { Toast } from 'vant';
import { is_post } from "../../Http/api.js";
import { deleteCookie } from '@/utils/env'
import music from '../../components/music.vue'
import vehicleInfo from '@/components/vehicleInfo'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import {PlateNum} from '@/api/carInfo'
import ObtainCarInfo from '@/components/ObtainCarInfo'
    export default {
        
        components: {
            music,vehicleInfo,ObtainCarInfo,
        },
         data() {
            return {
                fileList1: [],
                fileList3: [],
                fileList4: [],
                fileList5: [],
                imageFile: [],
                imageFileArr:[],
                isData:{},
                imageUrl:"",
                voiceShow:false,
                voice:'',
                longClick:0,
                timeOutEvent:0,
                fileList2:[],
                VinUrl:"",
                vindata:"",
                wximagelocalids:[],//判断上传张数
                toast:null,//转圈圈
                proveUrl:'',//销户
                up_files:[],
                isdefault:'',
                break_beam_photos:'',//断
                du4:'',
                ischu:2,
                CarInfoShow:false,
                imageHui:"",
                repetition:[],
            }
        },
        mounted () {
            if(this.isWechat){

                this.wcConfig();
            }
            this.getISFou();
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        methods: {
            colosepopup(){
                 this.CarInfoShow = false
            },
            comeWei(){
                this.CarInfoShow = false
                this.setInfo()
            },
            upDate(e){
                console.log(e)
                // this.$parent.vindata = e[0]
                this.vindata = e[0]
                this.CarInfoShow = false
                this.setInfo()
            },
            //上传留档照片
            uploaderImgArr(i){
                return file =>{
                    file.status = "uploading";
                    file.message = "上传中...";
                    if(file instanceof Array){
                        file.forEach(item=>{
                            this.upImages(i,item)
                        })
                    }else{
                        this.upImages(i,file)
                    }
                }
            },
            //批量上传
            upImages(i,file){
                file.status = "uploading";
                file.message = "上传中...";
                let params = new FormData(); //创建form对象
                params.append("file", file.file);
                this.$http.post("/index.php/index/upload/index", params)
                .then((res) => {
                    if (res.data.code == 0) {
                        file.status = "done";
                        this.imageFileArr.push(res.data.data)
                    }else if(res.data.code == -1){
                        this.islogout()
                    }else{
                        file.status = "failed";
                        file.message = "上传失败";
                    }
                }).catch(()=>{
                    this.imageFile = []
                    this.imageFileArr = []
                    file.status = "failed";
                    file.message = "上传失败";
                    this.$toast.fail('网络开小差了，请稍后重试！')
                })
            },
            //删除留档照片
            deleteimg2(file,datail){
                this.imageFile.forEach((item,index)=>{
                    if(index == datail.index){   // 判断删除的下标是否正确
                            this.imageFile.splice(index,1) // 使用遍历找到的index去数组里面删除
                        }
                })
                this.imageFileArr.forEach((item,index)=>{
                    if(index == datail.index){   // 判断删除的下标console.log(this.WXimg)
                        this.imageFileArr.splice(index,1) // 使用遍历找到的index去数组里面删除
                    }
                })
                // this.imageArr.forEach((item,index)=>{
                    
                //     if(index == datail.index){   // 判断删除的下标是否正确
                //             this.imageArr.splice(index,1) // 使用遍历找到的index去数组里面删除
                //         }
                // })
            },
            //校验
            beforeRead2(file){
                if(file instanceof Array){
                    for(let i=0;i<file.length;i++){
                        if ((file[i].type !== 'image/jpeg') && (file[i].type !== 'image/png')) {
                            Toast('请上传 jpg/png 格式图片');
                            return false;
                        }
                        return true;
                    }
                
                }else{
                    if((file.type !== 'image/jpeg') && (file.type !== 'image/png')) {
                        Toast('请上传 jpg/png 格式图片');
                        return false;
                    }
                    return true;
                }
            },
            lookinfo(){
                this.ischu = 1
                this.$nextTick(()=>{
                    this.$refs.infoCarRef.getQuality()
                    this.$refs.infoCarRef.wcConfig()
                })
            },
            infoOk(){
                this.onsubmit()

            },
            getISFou(){
                 this.$http.post('/index.php//setUp/info',{id:18}).then(res=>{
                    if(res.data.code == 0){
                        this.isdefault = res.data.data.default
                        if(this.isdefault == '是'){
                           
                        }
                    }else if(res.data.code == -1){
                          this.islogout()
                    }else{
                        this.$toast.fail(res.data.msg)
                    }
                 })
            },
            isVin(e){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                
                let text = `车架号：${e}`
                this.$http.post('index.php/wechat/makeYuYin',{text}).then(res=>{
                    this.voice =res.data.data
                    toast.clear()//清除加载效果
                    
                    var u = navigator.userAgent;
                    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if(isiOS){
                        this.voiceShow = true
                    }else{
                        this.$nextTick(()=>{
                            this.$refs.au.play()
                        })
                    }
                })
            },
            //长按
            start(){
                var that = this;
                    this.longClick = 0;
                    this.timeOutEvent = setTimeout(function() {
                    that.longClick = 1;
                    that.downImg()
                }, 500);
            },
             // 下载
            downImg() {
                if(!window.plus) return;
                    plus.gallery.save(this.imgUrl, function () {
                        // alert('保存成功!');
                    },function(){
                        // alert('保存失败，请重试!');
                });
            },
            yuying(){
                this.$refs.au.play()
                 this.voiceShow = false
            },
            isVin(e){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                
                let text = `车架号：${e}`
                this.$http.post('index.php/wechat/makeYuYin',{text}).then(res=>{
                    this.voice =res.data.data
                    toast.clear()//清除加载效果
                    
                    var u = navigator.userAgent;
                    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if(isiOS){
                        this.voiceShow = true
                    }else{
                        this.$nextTick(()=>{
                            this.$refs.au.play()
                        })
                    }
                })
            },
            async setInfo(){
                  try{
                    let zhaop = this.isWechat?this.imageUrl:this.du4
                     let data = encipherMent( JSON.stringify({
                        vin:this.vindata,
                        destruction_photos:this.imageHui,
                        destruction_video:this.up_files,
                        break_beam_photos:this.break_beam_photos,
                        vin_photos: this.isdefault == '是'? this.VinUrl :undefined,
                        path: this.isdefault == '是'? zhaop:undefined,
                        carNumberPlate:this.isData.carNumberPlate,
                        destruction_photos_data:this.imageFileArr
                    }))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/vehicles_upload/saveDestruction',param).then(res=>res)
                    
                    if(res.code === 0 ){
                        this.gouot()
                        this.$toast.success(res.msg)
                    }else{
                        this.$toast.fail(res.msg)
                    }
                }
                catch (err){
                    this.$toast.fail(err.msg)
                }
            },
          
            async onsubmit(){
                const {data} = await PlateNum({value:this.isData.carNumberPlate}).then(res=>res)
                console.log(data)
                let arr =  data.data
                if(arr.length==0){
                    this.setInfo()
                }else if(arr.length==1){
                    if(arr[0].carNumberPlate == this.isData.carNumberPlate && arr[0].vin == this.vindata){
                        this.setInfo()
                    }else{
                        this.repetition = arr
                        this.CarInfoShow = true
                            this.$nextTick(()=>{
                            this.$refs.ObtainRef.checkbox = []
                        })
                    }
                }else if(arr.length > 1){
                    this.repetition = arr
                    this.CarInfoShow = true
                        this.$nextTick(()=>{
                        this.$refs.ObtainRef.checkbox = []
                    })
                }
              
            },
            gouot() {
                this.$router.push('/Recognition')
            },
        //        //删除
            deleteimg(i){
                return (file,obj)=>{
                    if(i==1){
                         this.fileList4=[]
                         this.du4=''
                         this.isData = {}
                    }else if(i==2){
                        this.fileList1 = []
                    }else if(i==3){
                        this.vindata = ''
                        this.fileList2=[]
                    }else if(i==4){
                        this.break_beam_photos = ''
                        this.fileList5=[]
                    }
                }
              
            },
            //上传照片
           uploadershang(i){
                return file =>{
                     var toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "加载中..."
                    });
                    let params = new FormData(); //创建form对象
                    params.append("file", file.file);
                    this.$http.post("/index.php/index/upload/index", params)
                    .then((res) => {
                        toast.clear()//清除加载效果
                    if (res.data.code == 0) {
                            if(i=='hui'){
                                this.imageHui = res.data.data
                            }else if(i=='du'){
                                let url = 'index.php/vehicles_upload/vehicleCarNumberPlate'
                                this.du4 = res.data.data
                                this.$nextTick(()=>{
                                    this.$refs.infoCarRef.imageUrl = this.du4
                                })
                                
                                this.recognition(url,{path:res.data.data})
                            }else if(i=='vin'){
                                let url = '/index.php/index/bid_management.bid_order/apiBaiDu'
                               this.VinUrl = res.data.data
                               if(this.isdefault == '是'){
                                   this.$nextTick(()=>{
                                       this.$refs.infoCarRef.VinUrl = this.VinUrl
                                   })
                               }
                               this.recognition(url,{url:res.data.data},'vin')
                            }else if(i=='duan'){
                               this.break_beam_photos = res.data.data
                            }

                            file.status = "done";
                        } else if(res.data.code == -1){
                            deleteCookie('token')
                            this.$toast.fail(res.data.msg)
                            this.$router.push('/')
                        }else{
                            if(i=='du'){
                                this.du4 = ''
                                this.fileList4=[]//行驶证正面照片
                            }else if(i=='hui'){
                                this.imageHui = ''
                                this.fileList1=[]//行驶证正面照片
                            }else if(i=='vin'){
                               this.VinUrl = ''
                               this.fileList2=[]//行驶证正面照片
                            }else if(i=='vin'){
                               this.break_beam_photos = ''
                               this.fileList5=[]//行驶证正面照片
                            }
                            this.$toast.fail(res.data.msg)
                        }
                    }).catch(()=>{
                        if(i=='du'){
                             this.du4 = ''
                                this.fileList4=[]//行驶证正面照片
                        }else if(i=='hui'){
                            this.imageHui = ''
                            this.fileList1=[]//行驶证正面照片
                        }else if(i=='duan'){
                            this.VinUrl = ''
                            this.fileList2=[]//行驶证正面照片
                        }
                        toast.clear()//清除加载效果
                        this.$toast.fail('网络开小差了，请稍后重试！')
                        // file.status = "failed";
                        // file.message = "上传失败";
                    })
                }
                
           },
            afterRead(file) {
                 // 上传状态
                this.fileLoading = true;
                file.status = "uploading";
                // 状态提示
                file.message = "上传中...";
                // 声明form表单数据
                const formData = new FormData();
                // 添加文件信息
                formData.append("file", file.file);
                // 上传接口调用
                const toast = Toast.loading({
                    duration: 0,
                    forbidClick: true,
                    message: '上传...',
                    });
                this.$http.post('/index.php/index/upload_vedio/index',formData,{ timeout: 1200000 }).then((res) => {
              
                    // 存储返回数据
                    file.content = res.data.data
                    this.up_files.push(res.data.data)
                    // this.turn.up_files.push(res.data.data);
                    file.status = "done";
                    this.fileLoading = false;
                    toast.clear();
                    // console.log(this.turn.up_files);
                })
                .catch((e) => {
                    toast .clear();
                });
            },
            iconClose(i){
                this.up_files.splice(i,1)
                this.fileList3.splice(i,1)
            },
            beforeRead(file){
                
                if(file.type == 'video/mp4' || file.type == 'video/mov'|| file.type == 'video/webm'|| file.type == 'video/ogg'|| file.type == 'video/quicktime'){
                    return true;
                }else{
                    this.$toast.fail('请上传视频！')
                }
                    return false
            }
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.content-btn{
    margin: 50px 0;
}
.content{
    padding: 10px;
    padding-top: 50px;
    margin-top: 20px;
    text-align: center;
    .content-up{
        margin-top: 20px;
        padding: 10px;
      
    }
}
  .popup{
    width: 80%;
    padding: 20px;
    .btn-ok{
        width: 100%;
        display: flex;
        .van-button{
            flex: 1;
        }
    }
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
.videobox{
    .videoP{
       width: 100%;
       height: 195px;
    }
    position: relative;
    .iconX{
        position: absolute;
        top: 0;
        right: 0;
        background: #000;
        color: #fff;
        z-index: 99999;
    }

}
.opopzi{
    text-align: center;
    color: #1989fa;
}
.upimg{
    padding: 0 12px;
    .uploaderImg{
        display: inline-block;
        width: 100px ;
        height: 100px ;
        border: 1px solid #eee;
        position: relative;
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
}
.vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>