<template>
    <div class="con-warp">
        <van-form @submit="onSubmit" label-width='130' label-align="right"  >
            <van-field v-model="form.shopName" label="单位名称：" placeholder="请填写单位名称"  :rules="[{ required: true, message: '请填写单位名称' }]"/>
            <van-field
                readonly
                clickable
                name="picker"
                v-model="value"
                label="业务员选择："
                placeholder="点击选择业务员"
                @click="Picker = true"
                 :rules="[{ required: true, message: '请选择业务员' }]"
            />
            <van-popup v-model="Picker" position="bottom"  :close-on-click-overlay="false">
            <van-picker
                show-toolbar
                :columns="columns"
                value-key="realName"
                @confirm="onConfirm"
                @cancel="Picker = false"
            />
            </van-popup>
            <div class="isOkBtn">
                <van-button round @click="$emit('addditch',false)">取消</van-button>
                <van-button round  type="info" native-type="submit">确定</van-button>
            </div>
        </van-form>
      
    </div>
</template>

<script>
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { is_post } from "../../../Http/api";
    export default {
        data() {
            return {
                value: '',
                Picker:false,
                columns:[],
                form:{
                    salesmanId:"",
                    shopName:'',
                }
            }
        },
        methods: {
            onConfirm(v) {
                this.value = v.realName
                this.form.salesmanId = v.id
                this.Picker = false
            },
            async onSubmit(){
                try{
                    let a = encipherMent( JSON.stringify(this.form))
                    let param = new URLSearchParams();
                    param.append("value",a);
                    const data = await is_post('/index.php/index/Purchasing_Channels/phone_add',param).then(res=>res)
                    this.$emit('addditch',data.data)
                    this.$toast.success(data.msg)
                }
                catch{

                }
            },
            async getNmae(){
                this.form.salesmanId = ''
                this.form.shopName = ''
                this.$http.post('index.php/batch/userList').then(res=>{
                    this.columns = res.data.data
                })
               
            }
        },
    }
</script>

<style lang="scss" scoped>
.con-warp{
    padding: 20px;
}
.isOkBtn{
    margin-top: 50px;
    text-align: center;
    .van-button{
        width: 40%;
        margin: 0 5px;
    }
}
</style>